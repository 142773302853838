@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  margin: 0;
  font-family: "Inter", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

strong { font-weight: 600; }

.cdx-input:not(:first-of-type) { 
 margin-top: 8px;
}

.cdx-input::before{
  position: absolute !important;
  content: attr(data-placeholder) !important; 
  color: #707684 !important;
  font-weight: normal !important;
  opacity: 0 !important;
}

.cdx-input:empty::before {
  opacity: 1 !important;
}

.cdx-input:empty:focus::before {
  opacity: 0 !important;
}

.ce-block > div > *:not(.ce-block > div > .ce-paragraph):not(.ce-block > div > .ce-header):not(.ce-block > div > .cdx-nested-list) {
  padding: 5px 10px;
  margin: 10px auto;
  border: 2px solid #f0f0f0 !important;
  list-style-position: inside;
}
 